import React, { useEffect, useState, useRef } from "react"
import Seo from "../components/seo"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Footer from "../components/Footer"
import DynamicSlider from "../components/DynamicSlider"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import ComponentSelector from "../components/ComponentSelector"
import ShareWidget from "../components//ShareWidget"

export default function PolicyToolkit({ data, pageContext, location }) {
  const { slug, title, showFooterForm } = pageContext
  const { host, pathname } = location

  const {
    metadata,
    thematicTags,
    typeOfResource,
    relatedProjects,
    relatedFocusAreas,
    headerBackgroundImage,
  } = data.policyToolkit.nodes[0]


  const description = metadata?.description && metadata?.description;
  const image = metadata?.image && metadata?.image;


  const chapters = data.policyToolkit.nodes[0].policyToolkitChapters
  const footerComponents = data.policyToolkit.nodes[0].pageBottomComponents
  const firstChapter = chapters.map(chapter => chapter)
  const [activeChapter, setActiveChapter] = useState("")

  // = = = = = = = = = = = = =
  //Function to hide other chapters
  const showChapter = () => {
    const chapterParam = window.location.hash
    const singleChapter = chapters.map(chapter => chapter)
    const filteredChapter = chapters.filter(
      chapter => chapter.chapterId === chapterParam.replace("#", "")
    )
    chapterParam === ""
      ? setActiveChapter(singleChapter[0])
      : setActiveChapter(filteredChapter[0])
  }

  // = = = = = = = = = = = = =
  //UseEffect for chapters
  useEffect(() => {
    showChapter()
  }, [])

  useEffect(() => {
    showChapter()
  }, [])

  // = = = = = = = = = = = = =
  // Sliders Props
  const resourcesSliderProps = {
    limit: 6,
    cardColor: "#18988B",
    cardAlignment: "horizontal",
    headline: "<span>Related</span> Resources",
    link: [
      {
        label: "All resources",
        linkUrl: "/resources",
        isThisAButton: false,
      },
    ],
    relatedProjects: relatedProjects,
    relatedFocusAreas: relatedFocusAreas,
    contentType: [
      {
        contentType: "resource",
      },
    ],
    background: [
      {
        hasBackgroundImage: true,
      },
    ],
  }

  // Sidebar Nav Componenet
  const Sidebar = ({}) => {
    const [showSidebar, setShowSidebar] = useState(false)
    const [sidebarHeight, setSidebarHeight] = useState("")
    const sidebar = useRef(null)

    // = = = = = = = = = = = = =
    // Hooks

    useEffect(() => {
      // Get height of Sidebar to animate it in the handleClick() event listener
      setSidebarHeight(sidebar.current.scrollHeight)
    }, [showSidebar])

    // = = = = = = = = = = = = =
    // Eventhandlers
    const handleClick = () => {
      setShowSidebar(!showSidebar)
      !showSidebar
        ? (sidebar.current.style.maxHeight = `${sidebarHeight}px`)
        : (sidebar.current.style.maxHeight = "0")
    }

    return (
      <div className="sidebar-nav">
        <div
          onClick={() => handleClick()}
          className={`sidebar-nav__icon ${
            showSidebar ? `sidebar-nav__icon--open` : null
          }`}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>

        <ul
          ref={sidebar}
          className={`sidebar-nav__list ${
            showSidebar ? `sidebar-nav__list--open` : null
          }`}
        >
          {chapters.map(chapter => (
            <li
              className={`side-nav-item ${
                activeChapter?.chapterId === chapter?.chapterId && "active"
              }`}
            >
              <Link
                to={`#${chapter?.chapterId}`}
                onClick={() => setActiveChapter(chapter)}
              >
                {chapter.heading}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    )
  }

  // = = = = = = = = = = = = =
  // Toolkit Chapters
  const ToolkitChapters = ({}) => {
    return (
      <>
        {chapters.map((chapter, index) => (
          <div
            className={`chapter ${
              activeChapter?.chapterId === chapter?.chapterId
                ? "active-chapter"
                : "d-none"
            }`}
            id={chapter?.chapterId}
          >
            <h2 className="policy-toolkit__headline">{chapter?.heading}</h2>
            <ComponentSelector components={chapter?.chapterContent} />
          </div>
        ))}
      </>
    )
  }

  return (
    <>
      <Layout>
        <Seo
          title={title}
          description={description}
          image={image}
        />
        <div className="policy-toolkit-header hero small">
          <GatsbyImage
            image={getImage(headerBackgroundImage)}
            className="hero__bg"
          />

          <div className="container">
            <div className="row hero__breadcrumb">
              <div className="col justify-content-start">
                <Link to="/resources" className="oblique text-white">
                  ‹ Resources
                </Link>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <span className="tag">Policy Toolkit</span>
                <h1 className="text-white oblique">{title}</h1>
              </div>
            </div>
          </div>
        </div>

        <div className="policy-toolkit-page">
          <ShareWidget title={title} host={host} pathname={pathname} />
          <div className="container">
            <div className="row">
              <div className="col-md-3">{<Sidebar />}</div>
              <div className="col-md-8 toolkitContent">
                <ToolkitChapters />
              </div>
              <div className="col-md-1"></div>
            </div>
          </div>
        </div>

        <div className="footerComponents">
          <ComponentSelector components={footerComponents} />
        </div>

        <DynamicSlider component={resourcesSliderProps} />

        <Footer showFooterForm={showFooterForm} />
      </Layout>
    </>
  )
}

export const query = graphql`
  query ($slug: String!) {
    policyToolkit: allDatoCmsResource(filter: { slug: { eq: $slug } }) {
      nodes {
        typeOfResource
        policyToolkit
        thematicTags
        title
        metadata {
          description
          title
          image {
            url
          }
          twitterCard
        }
        headerBackgroundImage {
          gatsbyImageData(placeholder: BLURRED)
        }
        relatedFocusAreas {
          title
        }
        relatedProjects {
          title
        }
        policyToolkitChapters {
          componentId
          heading
          chapterId
          chapterContent {
            ... on DatoCmsTextContent {
              content
              componentId
            }
            ... on DatoCmsSocialMediaEmbed {
              smEmbed
              componentId
            }
            ... on DatoCmsInnerTextProjectCard {
              componentId
              description
              cardLink {
                label
                linkUrl
              }
              headline
              tag
            }
            ... on DatoCmsInnerPostSignupForm {
              everyActionFormLink
              componentId
            }
            ... on DatoCmsNarrativeTextBlock {
              componentId
              textContent
              hasImage
              blockWidth
              imageAlignment
              blockImage {
                featuredImage {
                  gatsbyImageData(placeholder: BLURRED)
                  title
                }
              }
            }
            ... on DatoCmsStatisticsRow {
              componentId
              id
              statistics {
                number
                description
              }
            }
            ... on DatoCmsInnerResourceReadingCta {
              componentId
              description
              headline
              typeOfCta
              buttonLink {
                label
                linkUrl
                isThisAButton
              }
              image {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            ... on DatoCmsNarrativeTextBlock {
              componentId
              textContent
              hasImage
              blockWidth
              imageAlignment
              blockImage {
                featuredImage {
                  gatsbyImageData(placeholder: BLURRED)
                  title
                }
              }
            }
            ... on DatoCmsVideoEmbed {
              componentId
              embedCode
              youtubeOrVimeo
              imageOverlay
              image {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
        }
        slug
        showFooterForm
        pageBottomComponents {
          ... on DatoCmsFormCtaBanner {
            componentId
            headline
            description
            donationForm
            formHeadline
            formLink
            amountButtons {
              amount
            }
            recurringDonationOptions {
              recurringDonation
            }
            image {
              gatsbyImageData(placeholder: BLURRED)
            }
            rightBackground {
              hasBackgroundImage
              backgroundColor
              image {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            leftBackground {
              hasBackgroundImage
              backgroundColor
              image {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
        }
      }
    }
  }
`
